import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = () => (
  <>
    <Seo />
    <Layout>404</Layout>
  </>
);

export default NotFoundPage;
